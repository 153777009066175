<template>
  <div class="privilege-dialog">
    <div class="privilege privilege-dialog-content">
      <div
        @click="onCloseIconClick"
        class="priviledge-dialog__close"
      >
        <Icon
          name="close-round-white"
          size="l"
        />
      </div>
      <div class="title">
        已获得声望特权
      </div>
      <div class="pri-content">
        <div
          v-for="(item,index) in privilegeInfo"
          :key="index"
          class="pri-item"
        >
          <div class="pri-icon">
            <img
              :src="item.icon"
              alt=""
            >
            <i
              v-if="item.msg"
              class="sub-icon"
            >{{ item.msg }}</i>
          </div>
          <div class="pri-text">
            {{ item.name }}
          </div>
        </div>
      </div>
      <Button
        @click="onJump"
        size="l"
        type="primary"
        class="open-btn"
      >
        打开腾讯游戏社区APP了解详情
      </Button>
    </div>
  </div>
</template>

<script>
// import VBtnJump from '@/page/embed-page/download-in-app/component/btn-jump/index.vue';
import Icon from '@/ui/icon';
import Button from '@/ui/button';
import { logEvent } from '../server';
import { launchByScheme } from '../util';

export default {
  name: 'Privilege',
  components: { Button, Icon },
  props: {
    privilegeInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isReport: false,
    };
  },

  methods: {
    report() {
      if (!this.isReport) {
        logEvent({ eventType: 2, extendId: 1, operId: '1901000010201' });
        this.isReport = true;
      }
      return true;
    },
    onCloseIconClick() {
      this.$emit('onClose');
    },
    onJump() {
      logEvent({ operId: '1901000010314' });
      launchByScheme();
    },
  },
};
</script>

<style>
</style>
