<template>
  <div
    @click="onJumpToPost"
    class="download-in-app__feed-item"
  >
    <div
      v-if="postInfo"
      class="left"
    >
      <div class="text">
        {{ title }}
      </div>
      <div class="info">
        {{ postInfo.readCnt | translateNumToText }}浏览
      </div>
    </div>
    <div
      v-if="coverUrl"
      class="right"
    >
      <div
        :style="{backgroundImage: `url(${coverUrl})`}"
        class="feed-item__image"
      />
    </div>
  </div>
</template>

<script>
// import UiSmallImage from '@/ui/image/small-image';
import { logEvent } from '../../server';
import { env } from '@/util/browser-env';
import { appendParams } from '@/util/uri';


/**
 * 根据帖子信息返回上报的类型
 * @param {object} info
 * @return {number} contentType
 * */
export function getReportContentTypeByInfo(info) {
  let contentType = 0; // 默认未知
  // 帖子信息里
  switch (String(info.type)) {
    case '1':
      if (info.sub_type) {
        contentType = String(info.sub_type) === '2' ? 2 : 1;
      } else { // 兜底是普通帖子
        contentType = 1;
      }
      break;
    case '2':
      contentType = 3;
      break;
    case '3':
      contentType = 4;
      break;
    default:
      break;
  }
  return contentType;
}

export default {
  name: 'DownloadInAppFeedItem',
  // components: {
  //   UiSmallImage,
  // },
  props: {
    data: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    postInfo() {
      return this.data?.feed?.postInfo;
    },
    image() {
      if (this.postInfo) {
        return this.postInfo?.pics?.info?.[0];
      }
      return null;
    },
    title() {
      return this.data.title || this.postInfo?.title;
    },
    coverUrl() {
      return this.data.coverUrl || this.image?.url;
    },
  },
  mounted() {
    if (this.postInfo) {
      logEvent({
        operId: '1904000010201',
        contentid: this.postInfo.id,
        contenttype: getReportContentTypeByInfo(this.postInfo),
        indexid: this.index + 1,
      });
    }
  },
  methods: {
    onJumpToPost() {
      logEvent({
        operId: '1904000010301',
        contentid: this.postInfo.id,
        contenttype: getReportContentTypeByInfo(this.postInfo),
        indexid: this.index + 1,
      });
      if (env.android) {
        // 安卓游戏内置浏览器使用$router.push无法回退页面
        let url = '/download/post-detail?';
        url = appendParams(url, {
          gameCode: this.$route.query.game_code,
          postId: this.postInfo.id,
          postType: this.postInfo.type,
          code: this.$route.query.code,
        });
        window.location.href = url;
      } else {
        this.$router.push({
          name: 'download-in-app-post-detail',
          query: {
            gameCode: this.$route.query.game_code,
            postId: this.postInfo.id,
            postType: this.postInfo.type,
            code: this.$route.query.code,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-in-app__feeds {
  color: #fff
}
</style>
