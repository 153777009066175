<template>
  <div class="h5-container">
    <UserInfo
      :user-info="pageData.userInfo"
      @clickTips="ruleDialogShow = !ruleDialogShow"
    />
    <div class="left-fixed">
      <div
        v-if="feedList.length"
        :class="{active:active==4}"
        @click="onFeedsTabClick"
        class="tab"
      >
        <div>
          <i class="icon icon-4" />
          <span>精选</span>
        </div>
      </div>
      <div
        v-if="giftList.length || signGiftList.length"
        :class="{active:active==0}"
        @click="onGiftTabClick"
        class="tab"
      >
        <div>
          <i class="icon icon-1" />
          <span>福利</span>
        </div>
        <div
          v-if="showGiftIcon"
          class="icon__gift"
        />
      </div>
      <div
        :class="{active:active==1}"
        @click="active=1"
        class="tab"
      >
        <div>
          <i class="icon icon-2" />
          <span>声望</span>
        </div>
      </div>
    <!-- v1.8.3 改成弹窗形式，不用再展示tab -->
    <!-- <div class="tab" :class="{active:active==2}" @click="active=2">
      <div>
        <i class="icon icon-3"></i>
        <span>声望特权</span>
      </div>
    </div> -->
    </div>

    <div class="right-container">
      <div
        id="right-wrap"
        class="right-wrap"
      >
        <!--礼包兑换-->
        <v-card-gift
          v-if="giftList.length || signGiftList.length"
          :class="{ hide: active != 0 }"
          :group-id="groupId"
          :gift-list="giftList"
          :sign-gift-list="signGiftList"
        />

        <!--声望值-->
        <CardSwiper
          v-if="pageData.rankInfo && pageData.userInfo"
          :class="{ hide: active != 1 }"
          :show="active === 1"
          :rank-info="pageData.rankInfo"
          :user-info="pageData.userInfo"
          @onShowPriviledge="onShowPriviledge"
        />

        <!--特权-->
        <PrivilegeDialog
          v-if="priviledgeDialogShow"
          :privilege-info="pageData.privilegeInfo"
          :user-info="pageData.userInfo"
          @onClose="priviledgeDialogShow=false"
        />

        <!-- 精选 -->
        <!-- 内部控制数据请求 -->
        <Feeds
          v-if="active===4"
          :init-list="feedList"
        />
      </div>
    </div>

    <v-rule
      v-model="ruleDialogShow"
      :rule="pageData.upRule"
    />

    <HTip />
  </div>
</template>
<script>
// import cookie from 'js-cookie';
import dayjs from 'dayjs';
import './source/fixable';
import VRule from '@/page/embed-page/download-in-app/component/rule.vue';
import VCardGift from '@/page/embed-page/download-in-app/component/card-gift.vue';
import Loading from '@/ui/loading';
import CardSwiper from './component/card-swiper.vue';
import PrivilegeDialog from './component/privilege-dialog.vue';
import UserInfo from './component/userinfo.vue';
import Feeds from './component/feeds';
import HTip from './component/h-tip.vue';
import './component/swiper/index';
import './component/swiper/swiper-3.4.2.min.css';
import {
  getSignGifts, getGiftList, getUserInfo, getGroupId, logEvent, getFeedsList,
} from './server';

const GIFT_TAB_SHOW_TIME_KEY = 'sx_gift_tab_show_time';

// 子项目
export default {
  components: {
    VCardGift,
    VRule,
    CardSwiper,
    PrivilegeDialog,
    UserInfo,
    HTip,
    Feeds,
  },
  data() {
    return {
      active: 1,
      ruleDialogShow: false,
      groupId: 0,
      pageData: {},
      giftList: [],
      signGiftList: [],
      reportMap: {
        0: 2,
        1: 0,
        2: 1,
      },
      priviledgeDialogShow: false,
      feedList: [],
      showGiftIcon: false, // 每天展示一次
    };
  },
  watch: {
    active(n) { // 0  1 2
      if (n in this.reportMap) {
        const id = 3 + this.reportMap[n]; // 上报事件id 3 4 5
        logEvent({ eventType: 3, extendId: id, operId: `190100001030${id}` });
      }
    },
  },
  async created() {
    this.init();
    this.checkGiftTabShow();
  },
  methods: {
    async init() {
      this.loading = true;
      const loadingInstance = Loading();
      try {
        [this.pageData, this.groupId, this.signGiftList, this.giftList, this.feedList] = await Promise.all([
          getUserInfo(),
          getGroupId(),
          getSignGifts(),
          getGiftList(),
          this.getFeedsData(),
        ]);
        if (this.feedList.length) {
          this.active = 4;
        } else if (this.giftList.length || this.signGiftList.length) {
          this.active = 0;
          this.setGiftTabShowTime();
        }
      } catch (error) {
        this.$logger.error('download init err', error);
        this.pageError = true;
      }
      loadingInstance.close();
      this.loading = false;
      logEvent({ eventType: 1, extendId: 1, operId: '1901000010101' });
    },
    onShowPriviledge() {
      this.priviledgeDialogShow = true;
      logEvent({ operId: '1901000010214' });
    },
    getFeedsData() {
      return getFeedsList({
        context: this.context,
      }).then(res => res.list);
    },
    // 点击福利tab
    onGiftTabClick() {
      this.active = 0;
      logEvent({ operId: '1905000010101' }); // 福利tab曝光
      if (this.showGiftIcon) {
        logEvent({ operId: '1905000010301' }); // 签到领奖提示点击
      }
      this.showGiftIcon = false;
      this.setGiftTabShowTime();
    },
    // 检查是否展示礼包图标。礼包图标每天展示一次
    checkGiftTabShow() {
      const giftTabShowTime = localStorage.getItem(GIFT_TAB_SHOW_TIME_KEY);
      if (giftTabShowTime && dayjs(parseInt(giftTabShowTime, 10)).isSame(dayjs(), 'day')) {
        // 如果是同一天，就不展示礼包图标
        // 不做处理
      } else {
        this.showGiftIcon = true;
        logEvent({ operId: '1905000010201' }); // 签到领奖提示点击
      }
    },
    // 设置是否展示礼包图标
    setGiftTabShowTime() {
      localStorage.setItem(GIFT_TAB_SHOW_TIME_KEY, (new Date()).getTime());
    },
    // 点击精选tab
    onFeedsTabClick() {
      this.active = 4;
    },
  },
};
</script>
<style lang="scss">
//横屏
//横屏适配 根据自己的页面需求酌情调节比例
$radio: 110;

//iphone4,5/se
@media only screen and (max-width: 600px) {
  @function tpx($width) {
    @return 0.83 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

//iphone6,7,8  ,iphone6,7,8 plus   Galaxy S5 ,Nexus 6 6p, Microsoft , LG
@media only screen and (min-width: 601px) and(max-width: 1000px) {
  @function tpx($width) {
    @return $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

// Nexus 10, Laptop ,Kindle ire,
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  //ipad 以上
  @function tpx($width) {
    @return 1.7 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

//iPad Pro ,
@media only screen and (min-width: 1301px) and (max-width: 1600px) {
  //ipad 以上
  @function tpx($width) {
    @return 2 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

@media only screen and (min-width: 1601px) {
  //ipad 以上
  @function tpx($width) {
    @return 2.5 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}
</style>
