<template>
  <div
    @click="onClick"
    class="download-in-app__banner-item"
  >
    <div
      :style="{ backgroundImage: `url(${banner.picUrl})` }"
      class="banner-image"
    />
    <div class="banner-content">
      <div
        v-if="banner"
        class="banner-text"
      >
        {{ banner.title }}
      </div>
    </div>
  </div>
</template>

<script>
// import UiSmallImage from '@/ui/image/small-image';
import uniOpenUrl from '@/util/uni-open-url';
import { logEvent } from '../../server';

export default {
  name: 'DownloadInAppBannerItem',
  // components: {
  //   UiSmallImage,
  // },
  props: {
    data: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    url() {
      return {
        url: 'https://via.placeholder.com/3000x444',
      };
    },
    banner() {
      return this.data.banner;
    },
  },
  mounted() {
    logEvent({
      operId: '1904000010202',
      contentid: this.banner.sourceId,
      indexid: this.index + 1,
    });
  },
  methods: {
    onClick() {
      if (this.banner?.jumpUrl) {
        logEvent({
          operId: '1904000010303',
          contentid: this.banner?.sourceId,
          indexid: this.index + 1,
        });
        uniOpenUrl(this.banner?.jumpUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download-in-app__feeds {
  color: #fff;
}
</style>
