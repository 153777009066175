<template>
  <div
    :class="{hide:!show}"
    class="w-pop"
  >
    <div class="w-pop-img" />
  </div>
</template>

<script>
// const utils = {
//   debounce(func, delay) {
//     let timer = null;
//     return function (...args) {
//       const context = this;
//       clearTimeout(timer);
//       timer = setTimeout(() => {
//         func.apply(context, args);
//       }, delay);
//     };
//   },
// };

export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
    setInterval(this.checkOrientation, 5000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      const cw = document.documentElement.clientWidth;
      // eslint-disable-next-line no-underscore-dangle
      let _Width = 0;
      // eslint-disable-next-line no-underscore-dangle
      let _Height = 0;
      const sw = window.screen.width;
      const sh = window.screen.height;
      // 2.在某些机型（如华为P9）下出现 srceen.width/height 值交换，所以进行大小值比较判断
      _Width = sw < sh ? sw : sh;
      _Height = sw >= sh ? sw : sh;

      if (cw === _Width) {
        // 竖屏
        this.show = true;
      } else if (cw === _Height) {
        // 横屏
        this.show = false;
        // 上述判断可能不准，因为clientWidht和screenWidth不一定相同
        // 如果上述都不名字，就走原有orientation判断作为兜底逻辑
        // 据说 orientation 也不一定准，所以作为兜底
      } else if (window.orientation === 180 || window.orientation === 0) {
        if (navigator && navigator.userAgent && navigator.userAgent.indexOf('tencent_game_emulator') >= 0) {
          this.show = false;
        } else {
          this.show = true;
        }
      } else {
        this.show = false;
      }
    },
  },
};
</script>
<style lang="scss">
  $radio: 80;
  @function tpx($width) {
    @return $width/$radio * 1rem;
  }

  .w-pop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    z-index: 11000;
  }

  .w-pop-img {
    width: tpx(256);
    height: tpx(215);
    background: url("https://static.gameplus.qq.com/business/gameh5/icon_turnh.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    margin-left: -1.5rem;
    top: 50%;
    margin-top: -1.2rem;
    /* animation: rotateA 1.5s ease infinite alternate; */
  }

  @keyframes rotateA {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    70% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }
</style>
