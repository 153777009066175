<!-- eslint-disable vue/require-component-is -->
<template>
  <div class="download-in-app__feeds">
    <ScrollView
      :loading="isLoading"
      :error="isError"
      :no-more="isEnd"
      :no-more-text="list.length > 4 ? '没有更多了哦' : ''"
      @scroll="onScroll"
      @reachBottom="onReachBottomHandler"
      @bottomErrorClick="onBottomErrorClickHandler"
    >
      <ul class="feeds-list">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="feeds-li"
        >
          <component
            :is="item.type === 1 ? 'BannerItem' : 'FeedItem'"
            :index="index"
            :data="item"
          />
        </li>
      </ul>
    </ScrollView>
    <div class="bottom-bar" />
  </div>
</template>

<script>
import ScrollView from '@/ui/scroll-view';
import FeedItem from './feed-item';
import BannerItem from './banner-item';
// import Loading from '@/ui/loading/index.vue';
import { getFeedsList, logEvent } from '../../server';

export default {
  name: 'DownloadFeeds',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FeedItem,
    ScrollView,
    // eslint-disable-next-line vue/no-unused-components
    BannerItem,
    // Loading,
  },
  props: {
    initList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      isEnd: false,
      context: null,
      list: [],
      staytimeStart: (new Date()).getTime(),
      isScrollEventReported: false,
    };
  },
  mounted() {
    if (this.initList && this.initList.length) {
      this.list = [...this.initList];
      this.isLoading = false;
    }
    this.getFeedsData();
    logEvent({ operId: '1904000010101' }); // 精选tab曝光

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.reportStayTime();
      } else {
        this.staytimeStart = (new Date()).getTime();
        // console.log('页面展示', this.staytimeStart);
      }
    }, false);
  },
  beforeDestroy() {
    this.reportStayTime();
  },
  methods: {
    getFeedsData(isLoadMore = false) {
      this.isLoading = true;
      return getFeedsList({
        context: isLoadMore ? this.context : null,
      }).then((res) => {
        // console.log('getFeedsData res', res);
        if (isLoadMore) {
          this.list = this.list.concat(res.list);
        } else {
          this.list = this.list;
        }
        this.isEnd = !!res.isEnd;
        this.context = res.context;
      })
        .catch((err) => {
          this.$logger.error('getFeedsList', err);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onReachBottomHandler() {
      if (!this.isEnd && !this.isLoading) {
        this.getFeedsData(true);
      }
    },
    onBottomErrorClickHandler() {
      // console.log('onBottomErrorClickHandler');
    },
    reportStayTime() {
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      if (staytime > 0 && staytime < 3600) {
        logEvent({ operId: '1904000010601', staytime });
      }
      this.staytimeStart = now;
    },
    onScroll() {
      if (!this.isScrollEventReported) {
        logEvent({ operId: '1904000010401' });
        this.isScrollEventReported = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
